import React, { useState } from 'react'
import { FirebaseContext } from "gatsby-plugin-firebase"
import moment from 'moment'

import Layout from '../components/Layout'
import { css } from '@emotion/core'

export default () => {

    const firebase = React.useContext(FirebaseContext)

    const [type,settype] = useState('artist')
    const [name,setname] = useState('')
    const [phone,setphone] = useState('')
    const [email,setemail] = useState('')
    const [message,setmessage] = useState('')

    return (
        <Layout>
            <div className="contact">
                <div css={css`
                    @media only screen and (max-width: 26.5625em) {
                        padding: 2.5rem !important;
                    }
                `} className="contact__container">
                    <div className="content">
                        <h1>Contact</h1>
                        <ul>
                            <li className={type === 'artist' ? 'active' : ''} onClick={() => settype('artist')}>.I'm an Artist!</li>
                            <li className={type === 'general' ? 'active' : ''} onClick={() => settype('general')}>.I have general inquiries!</li>
                        </ul>
                    </div>
                    {type === 'artist' ? <form onSubmit={e => e.preventDefault() && false}>
                        <h2>¿Eres artista? Escríbenos.</h2>
                        <div className="input-container">
                            <label>Name</label>
                            <input value={name} onChange={e => setname(e.target.value)} placeholder="Name" type="text"/>
                        </div>
                        <div className="input-container">
                            <label>Phone</label>
                            <input value={phone} onChange={e => setphone(e.target.value)} placeholder="Phone" type="text"/>
                        </div>
                        <div className="input-container">
                            <label>Email</label>
                            <input value={email} onChange={e => setemail(e.target.value)} placeholder="Email" type="mail"/>
                        </div>
                        <div className="input-container">
                            <label>Message</label>
                            <textarea rows="5" value={message} onChange={e => setmessage(e.target.value)} placeholder="Message"/>
                        </div>
                        <button onClick={handleSubmit}>Enviar mensaje</button>
                    </form> : type === 'general' ? <form onSubmit={e => e.preventDefault() && false}>
                        <h2>¿Tienes preguntas? Contáctanos.</h2>
                        <div className="input-container">
                            <label>Name</label>
                            <input value={name} onChange={e => setname(e.target.value)} placeholder="Name" type="text"/>
                        </div>
                        <div className="input-container">
                            <label>Phone</label>
                            <input value={phone} onChange={e => setphone(e.target.value)} placeholder="Phone" type="text"/>
                        </div>
                        <div className="input-container">
                            <label>Email</label>
                            <input value={email} onChange={e => setemail(e.target.value)} placeholder="Email" type="mail"/>
                        </div>
                        <div className="input-container">
                            <label>Message</label>
                            <textarea rows="5" value={message} onChange={e => setmessage(e.target.value)} placeholder="Message"/>
                        </div>
                        <button onClick={handleSubmit}>Enviar mensaje</button>
                    </form> : ''}
                </div>
            </div>
        </Layout>
    )

    async function handleSubmit () {
        try {
            if (name && email && message) {
                var item = {
                    name,
                    email,
                    message,
                    phone,
                    type,
                    timestamp: moment().valueOf(),
                    page: 'Contacto'
                }
                const ref = firebase.firestore().collection('contact').doc()
                item.id = ref.id
                return ref.set(item).then(() => {
                    const emailRef = firebase.firestore().collection('emails').doc()
                    item.message = `
                            <div style="max-width:600px; width:100%;">
                                <p>Alguien escribió al formulario de contacto.</p>
                                <ul>
                                    <li><b>Tipo: </b> ${type}</li>
                                    <li><b>Nombre de Cliente: </b> ${name}</li>
                                    <li><b>Email de Cliente: </b> ${email}</li>
                                    <li><b>Teléfono de Cliente: </b> ${phone}</li>
                                    <li><b>Mensaje de Cliente: </b> ${message}</li>
                                </ul>
                                <p>Gracias,</p>
                                <p>Columna Mx.</p>
                            </div>
                        `
                    item.email = 'alejandro@columnamx.com, info@columnamx.com, sam@columnamx.com'
                    // item.email = 'ebernal.n98@gmail.com'
                    item.subject = 'Mensaje de formulario de contacto'
                    item.text = 'Mensaje de formulario de contacto'
                    emailRef.set(item).then(() => {
                        setname('')
                        setemail('')
                        setmessage('')
                        setphone('')
                        alert('Tu mensaje ha sido enviado, un asesor se pondrá en contacto contigo')
                    })
                })
            } else {
                alert('Escribe un nombre, email y mensaje para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}